import { Button } from "@9amhealth/shared";
import styled from "@emotion/styled";
import Translate from "components/Translate/Translate";
import type { FC } from "react";
import React from "react";
import { IconCheckmarkSunrise, IconRocket } from "src/constants/icons";
import translate from "src/lib/translate";
import { tracker, useBloc } from "src/state/state";
import UserPreferencesCubit from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { APP_BREAKPOINT } from "src/constants/layout";

const HeaderIcon = styled.div`
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gradient-sunrise);
  margin-bottom: 0.5rem;
  border-radius: 50%;
  position: relative;

  svg {
    z-index: 1;
  }

  &:after {
    content: "";
    display: block;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 50%;
    position: absolute;
    background: var(--color-cream);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-light);
  border-radius: 0.5rem;
  padding: 1.25rem;
`;

const InfoPoint = styled.div`
  display: grid;
  grid-template-columns: auto 3fr;
  margin-bottom: 1.25rem;
  gap: 1rem;
`;

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${APP_BREAKPOINT}px) {
    width: 100%;
  }
`;

const FastPassPopup: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();

  const [, { firstName }] = useBloc(UserPreferencesCubit);

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const handleSkipVisit = () => {
    tracker.track(`Fast Pass - Skipped Visit`);
    document.dispatchEvent(new CustomEvent("skip-visit"));
  };

  const handleScheduleVisit = () => {
    tracker.track(`Fast Pass - Scheduled Visit`);
    document.dispatchEvent(new CustomEvent("schedule-visit"));
  };

  return (
    <InDialog
      popup
      title={translate("onboardingSteps.step.scheduleFirstVisit")}
      onClose={onClose}
      returnUrl={returnUrl}
    >
      <Container>
        <HeaderWrapper>
          <HeaderIcon>
            <IconRocket />
          </HeaderIcon>
          <h4>
            <Translate msg="onboarding.fastPass.title" />
          </h4>
          <p>
            <Translate
              msg="onboarding.fastPass.description"
              variables={{
                firstName
              }}
            />
          </p>
        </HeaderWrapper>

        <InfoWrapper>
          <InfoPoint>
            <IconCheckmarkSunrise />
            <div>
              <strong>
                <Translate msg="onboarding.fastPass.infoPoint.title_1" />
              </strong>

              <p>
                <Translate msg="onboarding.fastPass.infoPoint.description_1" />
              </p>
            </div>
          </InfoPoint>

          <InfoPoint>
            <IconCheckmarkSunrise />
            <div>
              <strong>
                <Translate msg="onboarding.fastPass.infoPoint.title_2" />
              </strong>

              <p>
                <Translate msg="onboarding.fastPass.infoPoint.description_2" />
              </p>
            </div>
          </InfoPoint>

          <InfoPoint>
            <IconCheckmarkSunrise />
            <div>
              <strong>
                <Translate msg="onboarding.fastPass.infoPoint.title_3" />
              </strong>

              <p>
                <Translate msg="onboarding.fastPass.infoPoint.description_3" />
              </p>
            </div>
          </InfoPoint>
        </InfoWrapper>

        <ButtonsWrap>
          <ButtonWrap>
            <Button
              onPress={handleSkipVisit}
              style={{ marginBottom: "1rem", width: "100%" }}
            >
              <Translate msg="onboarding.fastPass.skipVisit" />
            </Button>
          </ButtonWrap>

          <Button onPress={handleScheduleVisit} theme="transparent" hideArrow>
            <Translate msg="onboarding.fastPass.scheduleVisit" />
          </Button>
        </ButtonsWrap>
      </Container>
    </InDialog>
  );
};

export default FastPassPopup;
